import React, { KeyboardEvent, useState } from 'react';
import { Item } from '../../types/Item';
import * as C from './styles';

type Props ={
    item: Item,
    onUpdate: (item: Item) => void
}

export const ListItem = ({item, onUpdate}: Props) => {

    const [isChecked, setIsChecked] = useState(item.done);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setIsChecked(e.target.checked);
        item.done = e.target.checked;
        onUpdate(item);
    }

    const handleClick = () =>{
        debugger
        item.done = !item.done;
        setIsChecked(item.done);
        onUpdate(item);
    }

    
    return (
        <C.Container done={isChecked}>
            <input 
            type="checkbox" 
            checked={isChecked}
            onChange={handleChange}></input>
            <label onClick={handleClick}>{item.name}</label>            
        </C.Container>        
    );
} 
